.dashboard-recipes-page {
    .content {
        padding: 16px 0 24px 0;
        .recipes {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -16px;
            .recipe-card {
                margin: 16px;
                flex: 0 1 calc(33.33333% - 32px);
            }
        }
    }
}

@media(max-width: 991px) {
    .dashboard-recipes-page {
        .content {
            .recipes {
                .recipe-card {
                    flex: 0 1 calc(50% - 32px);
                }
            }
        }
    }
}

@media(max-width: 767px) {
    .dashboard-recipes-page {
        .content {
            padding: 0;
            .recipes {
                .recipe-card {
                    flex: 0 1 100%;
                }
            }
        }
    }
}