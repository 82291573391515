.food-row {
    display: flex;
    align-items: center;
    .food-name, .food-quantity {
        flex: 0 1 40%;
    }
    .action {
        margin-left: auto;
        .delete-food-button {
            cursor: pointer;
        }
    }
}