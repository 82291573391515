.login-page{
    background: #fff4eb;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .content-wrapper {
        width: 100%;
        padding: 16px;
    }
    .logo-section {
        width: 100%;
        text-align: center;
        img {
            max-width: 140px;
            margin-bottom: 48px;
        }
    }    
    .form-section{
        max-width: 600px;
        margin: auto;
        padding: 48px 64px;
        background: #fff;
        border-radius: 30px;
        -webkit-box-shadow: 0px 0px 10px 10px rgba(217, 217, 217, 0.1);
        box-shadow: 0px 0px 10px 10px rgba(217, 217, 217, 0.1);
        .form-title {
            margin-bottom: 32px;
            text-align: center;
            .title {
                font-size: 32px;
                margin-bottom: 8px;
                line-height: 1;
                color: #f1d100;
            }
            .subtitle {
                color: #666666;
                font-size: 14px;
            }
        }
        .input-wrapper, .link-wrapper {
            margin-bottom: 24px;
        }
        .link-wrapper {
            a {
                font-size: 12px;
                color: #666666;
            }
        }
        .button-wrapper {
            button {
                width: 100%;
            }
        }
        .registration-link-wrapper {
            margin-top: 32px;
            text-align: center;
            a {
                color: #f1d100;
            }
        }
    }
}

@media(max-width: 767px) {
    .login-page{ 
        .form-section {
            padding: 48px 24px;
        }
    }
}