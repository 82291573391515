.error-message-wrapper {
    background: #ffd4d4;
    padding: 8px 16px;
    border-radius: 8px;
    .error-message {
        display: flex;
        align-items: center;
        .message {
            font-weight: bold;
            color: #7c2222;
            margin-left: 8px;
        }
    }
}