.button {
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    border: none;
    outline: none;
    cursor: pointer;
    min-width: 120px;
    border: solid 2px transparent;
    border-radius: 30px;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
    font-family: 'Montserrat';
    transition: all .5s;
    .loading-label {
        margin-left: 8px;
    }
    &:hover {
        transform: scale(1.05);
    }
    &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}
.primary-button {
    background: #f1d100;
    color: #fff;
}
.secondary-button {
    background: #ffffff;
    color: #f1d100;
    border-color: #f1d100;
    &:hover {
        color: #f1d100;
        border-color: #f1d100;;
    }
}
.delete-button {
    background: #c30010;
    color: #fff;
    &:hover {
        background: #9a000d;
    }
}
