.dashboard {
    // height: 100vh;
    width: 100%;
    // overflow: hidden;
    background: #fff4eb;
    .main-content {
        margin-left: 250px;
        min-height: 100vh;
        // overflow: hidden;
        .page-content {
            height: 100%;
            background: #fff4eb;
            padding: 32px 80px;
            .hero {
                position: sticky;
                top: 0;
                padding: 16px 8px;
                margin: 0 -8px;
                background: #fff4eb;
                z-index: 999;
            }
            .title {
                color: #e2813f;
            }
        }
    }
}

@media(max-width: 1200px) {
    .dashboard {
        .main-content {
            .page-content {
                padding: 32px 48px;
            }
        }
    }
}

@media(max-width: 991px) {
    .dashboard {
        .main-content {
            margin-left: 140px;
        }
    }
}

@media(max-width: 767px) {
    .dashboard {
        .main-content {
            margin-left: 0;
            .page-content {
                padding: 0 16px 95px 16px;
                .hero {
                    // text-align: center;
                    padding: 16px 8px 8px 8px;
                    h1 {
                        font-size: 24px;
                    }
                }
            }
        }
    }
}