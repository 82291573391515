.contest-card {
    background: #fff;
    border-radius: 30px;
    overflow: hidden;
    box-shadow: 0px 0px 10px 10px rgba(217, 217, 217, 0.1);
    display: flex;
    flex-direction: column;
    .contest-image {
        display: flex;
        img {
            max-width: 100%;
        }
    }
    .contest-content {
        flex: 1 1 auto;
        padding: 16px;
        display: flex;
        flex-direction: column;
        .contest-title {
            margin-bottom: 16px;
        }
        .contest-description {
            margin-bottom: 24px;
        }
        .button {
            width: 100%;
            margin-top: auto;
        }
    }
}