.children-card {
    background: #fff;
    border-radius: 30px;
    padding: 32px;
    box-shadow: 0px 0px 10px 10px rgba(217, 217, 217, 0.1);
    position: relative;
    &.add-button {
        background: #f1d100;
        color: #ffffff;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        .plus {
            font-size: 50px;
            line-height: 0.6;
            margin-bottom: 16px;
        }
    }
    .icons {
        display: flex;
        position: absolute;
        right: 16px;
        top: 16px;
        cursor: pointer;
        .delete-icon {
            margin-left: 8px;
        }
        svg {
            width: 18px;
        }
    }
    .text {
        .name {
            margin-bottom: 16px;
        }
        .subtitle {
            margin-top: 8px;
        }
    }
    .link-button {
        font-size: 14px;
        font-weight: bold;
        color: #f1d100;
        margin-top: 24px;
        text-decoration: underline;
        cursor: pointer;
    }
}

@media(max-width: 991px) {
    .children-card {
        margin-bottom: 24px;
    }
}

@media(max-width: 767px) {
    .children-card {
        padding: 16px 24px;
    }
}