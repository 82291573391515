.meal-skeleton-card {
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0px 0px 10px 10px rgba(217, 217, 217, 0.1);
    margin-bottom: 24px;
}

.meal-skeleton-inner {
    background-color: #fff;
    border-radius: 30px;
    width: 100%;
    height: 130px;
    position: relative;
    overflow: hidden;
}

.meal-skeleton-inner::before {
    content: '';
    position: absolute;
    top: 0;
    left: -150%;
    width: 150%;
    height: 100%;
    background: linear-gradient(to right, transparent 0%, rgba(244, 235, 235, 0.6) 50%, transparent 100%);
    animation: loading 1.5s infinite;
}

@keyframes loading {
    from {
        left: -150%;
    }
    to {
        left: 150%;
    }
}