.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  .loader {
    width: 30px;
    height: 30px;
    margin: 0;
  }
  .text {
    margin-top: 16px;
    color: #fff;
  }
}
  