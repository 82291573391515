.horizontal-calendar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .arrow-wrapper {
        padding: 8px;
        cursor: pointer;
        transition: all 0.5s;
        &:hover {
            transform: scale(1.3);
        }
    }
    .day {
        cursor: pointer;
    }
}