.calendar {
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    .cell {
        padding: 8px 0;
        margin: 8px;
        text-align: center;
        border-radius: 30px;
        cursor: pointer;
        transition: all .5s;
        &:hover {
            background: #f0f0f0;
        }
        &.active {
            background: #f1d100;
        }
    }
    .col-span-3 {
        grid-column: span 3 / span 3;
    }
}
