.dashboard-food-diary-page {
    .hero {
        .back-link {
            cursor: pointer;
            text-decoration: underline;
            display: inline-block;
            margin-bottom: 4px;
            span {
                margin-left: 8px    ;
            }
        }
    }
    .horizontal-calendar {
        margin: 32px 0;
        position: sticky;
        top: 85px;
        background: #fff4eb;
        z-index: 1;
    }
    .general-cards-wrapper {
        display: flex;
        // align-items: center;
        margin: 0 -16px;
        .activity-card {
            flex: 0 1 calc(50% - 32px);
            margin: 0 16px;
            margin-bottom: 24px;
        }
    }
    .meals-wrapper {
        .meal-card {
            margin-bottom: 24px;
        }
    }
}

@media(max-width: 991px) {
    .dashboard-food-diary-page {
        .general-cards-wrapper {
            display: block;
        }
    }
}

@media(max-width: 767px) {
    .dashboard-food-diary-page {
        .horizontal-calendar {
            margin-top: 0;
            padding-top: 0;
            top: 70px;
        }
        .general-cards-wrapper {
            display: block;
            .activity-card {
                margin-bottom: 16px;
            }
        }
        .meals-wrapper {
            .meal-card {
                margin-bottom: 16px;
            }
        }
    }
}