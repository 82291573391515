*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    font-family: 'Montserrat' !important;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
}

/* Per WebKit (Chrome, Safari, Edge) */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Per Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.Toastify__toast {
    font-family: 'Montserrat' !important;
}

.Toastify__toast--success {
    background: #e8ffec;
    color: #009642;
}

.Toastify__toast--error {
    background: #ffe8e8;
    color: #960000;
}