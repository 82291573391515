.sidebar {
    width: 250px;
    z-index: 1;
    bottom: 0;
    margin-top: 0;
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: column;
    background: #FFF;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    box-shadow: 0px 0px 10px 10px rgba(217, 217, 217, 0.1);
    .logo {
        text-align: center;
        padding: 24px 12px;
        img {
            max-width: 100px;
        }
    }
    .sidebar-link {
        text-decoration: none;
        color: #e2813f;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 16px;
        border: solid 2px transparent;
        border-radius: 30px;
        margin-bottom: 24px;
        min-height: 58px;
        transition: all 0.5s;
        cursor: pointer;
        &.logout-mobile {
            display: none;
        }
        &.active, &:hover {
            background: #fff4eb;
            color: #e2813f;
            border-color: #e2813f;
        }
        .icon {
            flex: 0 0 20px;
            display: flex;
        }
        .text {
            margin-left: 8px;
            font-weight: bold;
        }
    }
    .dashboard-pages {
        flex: 70%;
        padding: 48px 24px 24px 24px;
    }
    .dashboard-exit{
        flex: 10%;
        padding: 24px;
    }
}

@media(max-width: 991px) {
    .sidebar {
        width: 140px;
        .sidebar-link {
            .text {
                display: none;
            }
        }
    }
}

@media(max-width: 767px) {
    .sidebar {
        width: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        top: inherit;
        border-radius: 0;
        left: 50%;
        transform: translateX(-50%);
        .logo, .dashboard-exit {
            display: none;
        }
        .dashboard-pages {
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 8px 16px;
            flex: 1 1 100%;
        }
        .sidebar-link {
            margin-bottom: 0;
            padding: 8px 16px;
            flex-direction: column;
            &.logout-mobile {
                display: flex;
            }
            .icon svg {
                height: 20px;
            }
            .text {
                display: block;
                margin: 0;
                margin-top: 4px;
            }
        }
    }
}