.add-food-modal {
    .modal-subtitle {
        margin-top: 16px;
        color: #666;
    }
    .modal-content {
        .form-wrapper {
            margin-top: 32px;
        }
        .modal-footer {
            margin-top: 32px;
            button {
                width: 100%;
            }
        }
    }
}