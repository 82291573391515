@import "../../scss/index";

.registration-page{
    background: #fff4eb;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    .content-wrapper {
        width: 100%;
        padding: 16px;
    }
    .logo-section {
        width: 100%;
        text-align: center;
        img {
            max-width: 140px;
            margin-bottom: 48px;
        }
    }    
    .form-section {
        max-width: 800px;
        margin: auto;
        padding: 48px 64px;
        background: #FFF;
        border-radius: 30px;
        -webkit-box-shadow: 0px 0px 10px 10px rgba(217, 217, 217, 0.1);
        box-shadow: 0px 0px 10px 10px rgba(217, 217, 217, 0.1);
        .form-title {
            margin-bottom: 48px;
            text-align: center;
            .title {
                font-size: 32px;
                margin-bottom: 8px;
                line-height: 1;
                color: #f1d100;
            }
            .subtitle {
                color: #666666;
                font-size: 14px;
            }
        }
        .steps-wrapper {
            display: flex;
            align-items: center;
            margin: 0 -12px 32px -12px;
            .step-button {
                flex: 0 1 50%;
            }
        }
        .form-inputs {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -12px;
            .input-wrapper {
                flex: 0 1 calc(50% - 24px);
                margin: 0 12px 24px 12px;
            }
            .button-wrapper {
                flex: 0 1 calc(50% - 24px);
                margin: 21px 12px 24px 12px;
                button {
                    width: 100%;
                }
            }
        }
        .step-button-wrapper {
            margin-top: 8px;
            button {
                margin-left: auto;
            }
        }
        .footer-link-wrapper {
            margin-top: 32px;
            text-align: center;
            a {
                color: #f1d100;
            }
        }
    }
    .signup-form {
        .form-inputs .input-wrapper.email,
        .form-inputs .input-wrapper.checkbox {
            flex: 0 1 calc(100% - 24px);
        }
        .button-wrapper {
            margin-top: 24px;
            button {
                width: 100%;
            }
        }
    }
    .login-data-form {
        .form-inputs .input-wrapper.email,
        .form-inputs .input-wrapper.checkbox {
            flex: 0 1 calc(100% - 24px);
        }
        .step-button-wrapper {
            display: flex;
            justify-content: space-between;
            button {
                margin-left: initial;
            }
        }
    }

    .error-message-wrapper {
        margin-top: 24px;
    }
}

@media(max-width: 767px) {
    .registration-page{ 
        .form-section {
            padding: 48px 24px;
            .form-inputs {
                display: block;
            }
        }
    }
}