.activity-card {
    background: #fff;
    border-radius: 30px;
    padding: 40px;
    box-shadow: 0px 0px 10px 10px rgba(217, 217, 217, 0.1);
    .header {
        display: flex;
        align-items: center;
        img {
            width: 70px;
        }
        .text {
            margin-left: 24px;
            .subtitle {
                margin-top: 8px;
            }
        }
    }
    .options {
        display: flex;
        margin: 0 -16px;
        margin-top: 24px;
        .option {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 8px;
            padding: 16px;
            flex: 0 1 calc(33.333333% - 16px);
            border: solid 2px #EFEFEF;
            background: #f9f9f9;
            border-radius: 16px;
            text-align: center;
            cursor: pointer;
            &.selected {
                border-color: #f1d100;
                background: #fff5b6;
            }
        }
    }
}

@media(max-width: 991px) {
    .activity-card {
        margin-bottom: 24px;
    }
}

@media(max-width: 767px) {
    .activity-card {
        padding: 16px 24px;
        .header {
            img {
                width: 60px;
            }
        }
        .options {
            .option {
                padding: 8px;
            }
        }
    }
}