$black:#171717;
$dark-gray:#494949;
$light-gray: #fbfbfb;
$white:#FEFEFE;
$yellow:#e6d24b;

.c-black {
    color: $black;
}

.c-gray {
    color:$dark-gray;
}

.c-white {
    color: $white;
}

.c-yellow {
    color:$yellow;
}