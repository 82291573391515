.input-wrapper {
    position: relative;
    label {
        font-size: 14px;
        display: block;
        color: #666666;
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        font-weight: bold;
        svg {
            margin-right: 10px;
        }
    }
    .input {
        position: relative;
        .suffix {
            position: absolute;
            top: 0;
            right: 16px;
            height: 100%;
            display: flex;
            align-items: center;
            color: #666666;
        }
    }
    &.password-input {
        .show-password{
            position: absolute;
            cursor: pointer;
            top: 32px;
            right: 16px;
            color: #666;
        }
    }
    input, select, textarea {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-size: 14px;
        outline: none;
        box-sizing: border-box;
        color: #2b2b2b;
        border: solid 2px #EFEFEF;
        border-radius: 30px;
        padding: 10px 16px;
        font-family: 'Montserrat';
        min-width: 200px;
        width: 100%;
        &:focus {
            border-color: #f1d100;
            background-color: #FFFDF0 !important;
        }
        &:disabled, &:disabled:focus {
            color: #494949;
            cursor: not-allowed;
        }
    }
    /* iOS specific styles */
    @supports (-webkit-touch-callout: none) {
        input[type="time"] {
            border: solid 2px #EFEFEF;
            font-size: 14px;
            padding: 10px 16px;
            border-radius: 30px;
            background-color: #fff;
            color: #2b2b2b;
            min-height: 42px;
        }
    }
    .tox-editor-container {
        border: solid 2px #EFEFEF;
        border-radius: 8px;
    }
    textarea {
        resize: none;
    }
    .custom-select__control {
        box-sizing: border-box;
        color: #f1d100;
        border: solid 2px #EFEFEF;
        margin: 0;
        border-radius: 30px;
        font-size: 14px;
        min-height: 40px;
        outline: none;
        padding-left: 8px;
        &:hover {
            border-color: #EFEFEF;
        }
        &--is-focused {
            box-shadow: none;
        }
    }
    .custom-select__option {
        font-size: 14px;
        &--is-focused {
            background: #EFF0F6;
        }
        &--is-selected {
            background: #f1d100;
        }
    }
    .custom-select__single-value {
        padding-left: 2px;
    }
    .custom-select__multi-value {
        background: #FFF;
        border-radius: 8px;
        .custom-select__multi-value__remove {
            border-radius: 8px;
        }
    }
    .custom-select__menu {
        z-index: 999;
    }

    &.has-error {
        input, select, .custom-select__control, textarea {
            border-color: red;
        }
    }

    svg.eye {
        position: absolute;
        cursor: pointer;
        top: 34px;
        right: 10px;
    }

    &.checkbox, &.radio {
        /* The container */
        .label {
            display: flex;
            align-items: center;
            padding-left: 35px;
            min-height: 20px;
            margin: 0;
            font-weight: normal;
            a {
                color: #666;
            }
        }
        
        /* Hide the browser's default checkbox */
        .label input {
            position: absolute;
            opacity: 0;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            cursor: pointer;
            height: 100%;
            width: 100%;
            z-index: 1;
            border: none;
        }

        /* Create a custom checkbox */
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: #FFF;
            border: solid 2px #efefef;
        }

        .checkbox-text {
            z-index: 10000;
        }

        &.has-error {
            .checkmark {
                border-color: red;
            }
        }
        
        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }

        /* Show the checkmark when checked */
        .label input:checked ~ .checkmark:after {
            display: block;
        }
    }

    &.checkbox {
        /* Create a custom checkbox */
        .checkmark {
            border-radius: 4px;
        }

        /* When the checkbox is checked, add a green background */
        .label input:checked ~ .checkmark {
            background-color: #f1d100;
            border-color: #f1d100;
        }

        /* Style the checkmark/indicator */
        .label .checkmark:after {
            left: 5px;
            top: 0px;
            width: 5px;
            height: 10px;
            border: solid #FFF;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        p {
            z-index: 2;
        }
    }

    &.radio {
        /* Create a custom radio button */
        .checkmark {
            border-radius: 50%;
        }

        /* When the checkbox is checked, add a green background */
        .label input:checked ~ .checkmark {
            background-color: #FFF;
        }

        /* Style the indicator (dot/circle) */
        .label .checkmark:after {
            top: 2px;
            left: 2px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: yellow;
        }
        
    }

    .help-box {
        margin-top: 24px;
        background: #6E7191;
        padding: 16px 24px;
        line-height: 16.8px;
        color: #FFF;
        border-radius: 8px;
        position: absolute;
        z-index: 1;
    } 
}

.input-wrapper, .input-score-select, .input-emoji-select {
    .input-error {
        color: red;
        font-size: 12px;
        margin-top: 8px;
        display: flex;
        align-items: center;
    }
}

.input-score-select, .input-emoji-select, .input-beverage-select {
    .label, .description {
        color: #666666;
    }
    .options {
        display: flex;
        align-items: center;
        margin: 16px -8px 24px -8px;
        .option {
            flex: 0 1 50px;
            padding: 8px;
            margin: 0 8px;
            border: solid 2px #EFEFEF;
            border-radius: 30px;
            text-align: center;
            cursor: pointer;
            &.selected {
                border-color: #f1d100;
                background: #fff5b6;
            }
        }
    }
}

.input-emoji-select {
    .options {
        .option {
            img {
                width: 50px;
            }
        }
    }
}

.input-beverage-select {
    .options {
        .option {
            flex: 1 1 auto;
            img {
                height: 40px;
            }
        }
    }
}