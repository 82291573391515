.profile-card {
    background: #fff;
    border-radius: 30px;
    padding: 48px 40px;
    box-shadow: 0px 0px 10px 10px rgba(217, 217, 217, 0.1);
    .header {
        .card-title {
            display: block;
            height: 2px;
            width: 100%;
            margin: 16px 0 32px 0;
            background-color: #f1d100;
            position: relative;
            color: #f1d100;
            p {
                left: 48px;
                transform: translateY(-50%);
                background: #fff;
                display: inline-block;
                position: absolute;
                padding: 0 16px;
            }
        }
    }
    .body {
        padding-top: 32px;
        .personal-data-form {
            .form-inputs {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -12px;
                .input-wrapper {
                    flex: 0 1 calc(50% - 24px);
                    margin: 0 12px 24px 12px;
                }
                .button-wrapper {
                    flex: 0 1 calc(50% - 24px);
                    margin: 21px 12px 24px 12px;
                    button {
                        width: 100%;
                    }
                }
            }
            .step-button-wrapper {
                display: flex;
                justify-content: flex-end;
                margin-top: 24px;
            }
        }
    }
}

@media(max-width: 767px) {
    .profile-card {
        padding: 32px 24px;
        .body {
            .personal-data-form {
                .form-inputs {
                    display: block;
                }
            }
        }
    }
}