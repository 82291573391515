$xxxl: 36px;
$xxl: 26px;
$xl: 22px;
$l: 20px;
$m: 18px;
$s: 16px;
$xs: 14px;
$xxs: 12px;

.font-light {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
}

.font-medium {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}

.font-bold {
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
  
}

.text-xxxl {
  font-size: $xxxl;
}

.text-xxl {
  font-size: $xxl;
}

.text-extra-large {
  font-size: $xl;
}

.text-large {
  font-size: $l;
}

.text-medium {
  font-size: $m;
}

.text-small {
  font-size: $s;
}

.text-xs {
  font-size: $xs;
}

.text-xxs {
  font-size: $xxs;
}

.text-underline {
  text-decoration: underline;
}
