.dashboard-profile-page {
    .content{
        padding: 48px 0;
        .update-password-card {
            margin-top: 48px;
        }
    }
}

@media(max-width: 767px) {
    .dashboard-profile-page {
        .content{
            padding: 16px 0 24px 0;
        }
    }
}