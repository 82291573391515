.registration-thank-you-page {
    background: #fff4eb;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .content-wrapper {
        width: 100%;
        padding: 16px;
    }
    .logo-section {
        width: 100%;
        text-align: center;
        img {
            max-width: 140px;
            margin-bottom: 48px;
        }
    }
    .message-section {
        text-align: center;
        .text {
            line-height: 1.5;
        }
        .title {
            margin-bottom: 24px;
            color: #f1d100;
        }
    }
    .page-loader {
        min-height: initial;
        margin: 32px 0;
    }
}