.meal-card {
    background: #fff;
    border-radius: 30px;
    padding: 40px;
    box-shadow: 0px 0px 10px 10px rgba(217, 217, 217, 0.1);
    &.collapsed {
        border: solid #f1d100;
        .header {
            .meal-info {
                .name {
                    color: #f1d100 !important;
                }
            }
        }
    }
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        .meal-info {
            display: flex;
            align-items: center;
            .image-wrapper {
                display: flex;
                img {
                    width: 50px;
                }
            }
            .name {
                margin-left: 24px;
                display: flex;
                align-items: center;
                &.saved {
                    color: #9dce99;
                }
                svg {
                    margin-left: 16px;
                }
            }
        }
    }
    .content {
        margin-top: 32px;
        display: flex;
        flex-wrap: wrap;
        .input-fields, .lists-wrapper {
            flex: 0 1 calc(50% - 33px);
        }
        .input-wrapper {
            margin-bottom: 24px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .input-score-select, .input-emoji-select {
            .input-error {
                margin-top: -16px;
                margin-bottom: 24px;
            }
        }
        .separator {
            border-right: solid 2px #EFEFEF;
            border-radius: 100px;
            margin: 0 32px;
        }
        .recipes-wrapper {
            margin-top: 40px;
        }
        .foods-wrapper, .recipes-wrapper {
            .name {
                color: #666666
            }
            .foods-table {
                background: #f6f6f6;
                padding: 16px;
                border-radius: 30px;
                margin-top: 16px;
                .table-header {
                    padding-bottom: 16px;
                    margin-bottom: 16px;
                    border-bottom: solid 2px #EFEFEF;
                    display: flex;
                    p {
                        flex: 0 1 40%;
                        color: #666666;
                    }
                }
                .empty-list {
                    padding: 24px 0;
                    color: #666;
                }
                .food-row {
                    margin: 8px 0;
                }
            }
            .add-food-button, .add-beverage-button {
                margin-top: 16px;
                display: inline-block;
                background: #9dce99;
                padding: 8px 16px;
                border-radius: 30px;
                font-weight: bold;
                color: #fff;
                cursor: pointer;
                transition: all .5s;
                p {
                    display: flex;
                    align-items: center;
                }
                .icon {
                    margin-right: 8px;
                    line-height: 1;
                }
                &:hover {
                    transform: scale(1.05);
                }
            }
            .add-food-button {
                background: #9dce99;
            }
            .add-beverage-button {
                background: #87cae7;
            }
            .error-message-wrapper {
                margin-top: 16px;
            }
        }
    }
    .footer {
        margin-top: 32px;
        .button {
            margin-left: auto;
        }
    }
}

@media(max-width: 991px) {
    .meal-card {
        .content {
            flex-direction: column;
            .lists-wrapper {
                margin-top: 24px;
            }
        }
    }
}

@media(max-width: 767px) {
    .meal-card {
        padding: 16px 24px;
        .header {
            .meal-info {
                .image-wrapper {
                    img {
                        width: 40px;
                    }
                }
            }
        }
    }
}