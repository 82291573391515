.dashboard-select-children-page {
    .hero {
        .subtitle {
            margin-top: 16px;
            color: #666;
        }
    }
    .childrens-cards-wrapper {
        display: flex;
        flex-wrap: wrap;
        // align-items: center;
        margin: 0 -16px;
        margin-top: 32px;
        .children-card {
            flex: 0 1 calc(25% - 32px);
            margin: 0 16px;
            margin-bottom: 24px;
        }
    }
}

@media(max-width: 1200px) {
    .dashboard-select-children-page {
        .childrens-cards-wrapper {
            .children-card {
                flex: 0 1 calc(33.33333% - 32px);
            }
        }
    }
}

@media(max-width: 991px) {
    .dashboard-select-children-page {
        .childrens-cards-wrapper {
            .children-card {
                flex: 0 1 calc(50% - 32px);
            }
        }
    }
}

@media(max-width: 767px) {
    .dashboard-select-children-page {
        .childrens-cards-wrapper {
            .children-card {
                flex: 0 1 calc(100% - 32px);
            }
        }
    }
}