.modal-wrapper {
    position: fixed; /* Stay in place */
    display: flex;
    overflow: auto;
    z-index: 999; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background: rgba(38, 38, 38, 0.5);
}

.modal-wrapper {
    &.mini {
        .modal-content {
            max-width: 600px;
            margin: auto;
            border-radius: 30px;
        }
    }
    .modal-content {
        background-color: #fff;
        padding: 32px;
        width: 80%;
        max-width: 1000px;
        position: relative;
        border-radius: 8px;
        margin: auto;
        // overflow-y: auto;
        .message-wrapper {
            margin-top: 32px;
        }
        .input-wrapper {
            margin-top: 24px;
        }
        .modal-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .modal-title {
                color: #666666;
            }
            .close {
                display: flex;
                width: 15px;
                height: 15px;
                border-radius: 15px;
                svg {
                    width: 10px;
                    cursor: pointer;
                }
            }
        }
        .modal-footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 48px;
            &.delete-modal-footer {
                justify-content: flex-end;
            }
            button.w-100 {
                width: 100%;
            }
        }
    }
}

@media(max-width: 767px) {
    .modal-wrapper {
        .modal-content {
            margin: auto;
            // margin-top: auto;
            // margin-bottom: 0;
            width: 100%;
            padding: 24px 16px;
        }
    }
}